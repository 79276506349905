import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AccessoriesProducts.css'; // Import your CSS file for styling
import wp1_1 from './assets/women/wp1_1.JPG';
import wp1_2 from './assets/women/wp1_2.JPG';
import wp1_3 from './assets/women/wp1_3.JPG';
import wp2_1 from './assets/women/wp2_1.jpg';
import wp2_2 from './assets/women/wp2_2.jpg';
import wp2_3 from './assets/women/wp2_3.jpg';
import wp2_4 from './assets/women/wp2_4.jpg';
import wp3_1 from './assets/women/wp3_1.jpg';
import wp3_2 from './assets/women/wp3_2.jpg';
import wp3_3 from './assets/women/wp3_3.jpg';
import wp4_1 from './assets/women/wp4_1.jpg';
import wp4_2 from './assets/women/wp4_2.jpg';
import wp4_3 from './assets/women/wp4_3.jpg';
import wp5_1 from './assets/women/wp5_1.jpg';
import wp5_2 from './assets/women/wp5_2.jpg';
import wp6_1 from './assets/women/wp6_1.jpg';
import wp6_2 from './assets/women/wp6_2.jpg';
import ap2_1 from './assets/accessories/ap2_1.JPG';
import ap2_2 from './assets/accessories/ap2_2.JPG';


const WomenProducts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="category-container">
      <h2>Women's Products</h2>
      <div className="card-container">
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={wp1_1} alt="Accessory 1 - 1" />
            </div>
            <div>
              <img src={wp1_2} alt="Accessory 1 - 2" />
            </div>
            <div>
              <img src={wp1_3} alt="Accessory 1 - 3" />
            </div>
          </Slider>
          <h3>AN07092</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={wp2_1} alt="Accessory 2 - 1" />
            </div>
            <div>
              <img src={wp2_2} alt="Accessory 2 - 2" />
            </div>
            <div>
              <img src={wp2_3} alt="Accessory 2 - 3" />
            </div>
            <div>
              <img src={wp2_4} alt="Accessory 2 - 3" />
            </div>
          </Slider>
          <h3>ARI07016</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={wp3_1} alt="Accessory 3 - 1" />
            </div>
            <div>
              <img src={wp3_2} alt="Accessory 3 - 2" />
            </div>
            <div>
              <img src={wp3_3} alt="Accessory 3 - 3" />
            </div>
          </Slider>
          <h3>ARI04167</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={wp5_1} alt="Accessory 3 - 1" />
            </div>
            <div>
              <img src={wp5_2} alt="Accessory 3 - 2" />
            </div>
          </Slider>
          <h3>ARI04214e</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={wp4_1} alt="Accessory 3 - 1" />
            </div>
            <div>
              <img src={wp4_2} alt="Accessory 3 - 2" />
            </div>
            <div>
              <img src={wp4_3} alt="Accessory 3 - 3" />
            </div>
          </Slider>
          <h3>ARI04185e</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={wp6_1} alt="Accessory 3 - 1" />
            </div>
            <div>
              <img src={wp6_2} alt="Accessory 3 - 2" />
            </div>
          </Slider>
          <h3>111</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={ap2_1} alt="Accessory 2 - 1" />
            </div>
            <div>
              <img src={ap2_2} alt="Accessory 2 - 2" />
            </div>
          </Slider>
          <h3>BLACK 001</h3>
        </div>
        {/* Add more cards as needed */}
      </div>
    </div>
  );
};

export default WomenProducts;
