import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Products from './Products';
import Contact from './Contact';
import MenProducts from './MenProducts';
import WomenProducts from './WomenProducts';
import AccessoriesProducts from './AccessoriesProducts';
import Header from './Header';
import Footer from './Footer';
import './App.css';
import Belts from './Belts';

function App() {
  
  return (
    <Router>
      <div className="App">
        <Header />
        <div style={{ marginTop: '60px' }}>
          <Routes>
            <Route path="/" element={
              <>
                <Home />
                <About />
                <Products />
                <Contact />
              </>
            } />
            
            <Route path="/products/men" element={<MenProducts />} />
            <Route path="/products/women" element={<WomenProducts />} />
            <Route path="/products/accessories" element={<AccessoriesProducts />} />
            <Route path="/products/belt" element={<Belts />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
