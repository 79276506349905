import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AccessoriesProducts.css'; // Import your CSS file for styling
import b1 from './assets/belt/b1.jpg';
import b2 from './assets/belt/b2.jpg';
import b3 from './assets/belt/b3.jpg';
import b4 from './assets/belt/b4.jpg';
import b5_1 from './assets/belt/b5_1.jpg';
import b5_2 from './assets/belt/b5_2.jpg';
import b6 from './assets/belt/b6.jpg'

const Belts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="category-container">
      <h2>Belts</h2>
      <div className="card-container">
        <div className="card">
        <div>
              <img src={b1} alt="Accessory 1 - 1" />
            </div>
          <h3>PPA 18</h3>
        </div>
        <div className="card">
        <div>
              <img src={b2} alt="Accessory 1 - 1" />
            </div>
          <h3>PPA 17</h3>
        </div>
        <div className="card">
        <div>
              <img src={b3} alt="Accessory 1 - 1" />
            </div>
          <h3>PPA 12</h3>
        </div>
        <div className="card">
        <div>
              <img src={b4} alt="Accessory 1 - 1" />
            </div>
          <h3>AN 101</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={b5_1} alt="Accessory 3 - 1" />
            </div>
            <div>
              <img src={b5_2} alt="Accessory 3 - 2" />
            </div>
          </Slider>
          <h3>AN 201</h3>
        </div>
        <div className="card">
        <div>
              <img src={b6} alt="Accessory 1 - 1" />
            </div>
          <h3>SA 23</h3>
        </div>
        {/* Add more cards as needed */}
      </div>
    </div>
  );
};

export default Belts;
