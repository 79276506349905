import React, { useState } from 'react';
import './Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: ''
  });
  const [submitSuccess, setSubmitSuccess] = useState(false); // State for success message
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const formEle = document.querySelector('form');
    const formDatab = new FormData(formEle);

    fetch(
      'https://script.google.com/macros/s/AKfycbwCezCqyRp57hEo3rbWdEYwCSIYdZgtd8-a0EGCnG2NcFw4gwJLuAY2NZaQIkAD3Q_d/exec',
      {
        method: 'POST',
        body: formDatab
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.text(); // Assuming the response is text, adjust to res.json() if JSON
      })
      .then((data) => {
        console.log('Response from Google Sheets:', data);
        setSubmitSuccess(true); // Set submit success state to true
        resetForm(); // Optionally reset the form fields after successful submission
      })
      .catch((error) => {
        console.error('Error:', error.message);
        setErrorMessage('Failed to submit form. Please try again.'); // Display error message
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const resetForm = () => {
    setFormData({
      Name: '',
      Email: '',
      Phone: ''
    });
  };

  return (
    <div id="contact" className="Contact-form">
      <h1>CONTACT FORM</h1>
      <h2>
        Get In Touch
      </h2>
      <div>
        {submitSuccess ? (
          <div className="success-message">Form submitted successfully!</div>
        ) : (
          <form className="form" onSubmit={handleSubmit}>
            <input
              placeholder="Your Name"
              name="Name"
              type="text"
              value={formData.Name}
              onChange={handleChange}
            />
            <input
              placeholder="Your Email"
              name="Email"
              type="text"
              value={formData.Email}
              onChange={handleChange}
            />
            <input
              placeholder="Your Phone"
              name="Phone"
              type="text"
              value={formData.Phone}
              onChange={handleChange}
            />
            <button type="submit">Submit</button>
          </form>
        )}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </div>
  );
}

export default Contact;
