import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AccessoriesProducts.css'; // Import your CSS file for styling
import mp1_1 from './assets/men/mp1_1.JPG';
import mp1_2 from './assets/men/mp1_2.JPG';
import mp2_1 from './assets/men/mp2_1.JPG';
import mp2_2 from './assets/men/mp2_2.JPG';
import mp3_1 from './assets/men/mp3_1.jpg';
import mp3_2 from './assets/men/mp3_2.jpg';
import mp4_1 from './assets/men/mp4_1.JPG';
import mp4_2 from './assets/men/mp4_2.JPG';
import mp5_1 from './assets/men/mp5_1.JPG';
import mp5_2 from './assets/men/mp5_2.JPG';
import mp6_1 from './assets/men/mp6_1.jpg';
import mp7_1 from './assets/men/mp7_1.jpg';
import mp8_2 from './assets/men/mp8_2.jpg';
import mp8_1 from './assets/men/mp8_1.jpg';
import mp8_3 from './assets/men/mp8_3.jpg';


const MenProducts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="category-container">
      <h2>Men's Products</h2>
      <div className="card-container">
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={mp1_1} alt="Accessory 1 - 1" />
            </div>
            <div>
              <img src={mp1_2} alt="Accessory 1 - 2" />
            </div>
          </Slider>
          <h3>1562</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={mp2_1} alt="Accessory 2 - 1" />
            </div>
            <div>
              <img src={mp2_2} alt="Accessory 2 - 2" />
            </div>
          </Slider>
          <h3>1564</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={mp3_1} alt="Accessory 3 - 1" />
            </div>
            <div>
              <img src={mp3_2} alt="Accessory 3 - 2" />
            </div>
          </Slider>
          <h3>ARI02532e</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={mp4_1} alt="Accessory 3 - 1" />
            </div>
            <div>
              <img src={mp4_2} alt="Accessory 3 - 2" />
            </div>
          </Slider>
          <h3>8356</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={mp5_1} alt="Accessory 3 - 1" />
            </div>
            <div>
              <img src={mp5_2} alt="Accessory 3 - 2" />
            </div>
          </Slider>
          <h3>1568</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={mp8_1} alt="Accessory 3 - 1" />
            </div>
            <div>
              <img src={mp8_2} alt="Accessory 3 - 2" />
            </div>
            <div>
              <img src={mp8_3} alt="Accessory 3 - 2" />
            </div>
          </Slider>
          <h3>AN 67</h3>
        </div>
        <div className="card">
            <div>
              <img src={mp6_1} alt="Accessory 3 - 1" />
            </div>
          <h3>AN 9551</h3>
        </div>
        <div className="card">
            <div>
              <img src={mp7_1} alt="Accessory 3 - 1" />
            </div>
          <h3>AN 907</h3>
        </div>
        {/* Add more cards as needed */}
      </div>
    </div>
  );
};

export default MenProducts;
