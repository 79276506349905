import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Correct import for useNavigate
import './Header.css';
import logo from './assets/logo.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavClick = (target) => {
    setIsMenuOpen(false); // Close the menu
    navigate('/'); // Navigate to the main page
    setTimeout(() => {
      document.getElementById(target).scrollIntoView({ behavior: 'smooth' });
    }, 100); // Delay to ensure navigation before scrolling
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} onClick={() => handleNavClick('home')} alt="Logo" />
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={isMenuOpen ? 'nav-links open' : 'nav-links'}>
        <button className="nav-link" onClick={() => handleNavClick('home')}>HOME</button>
        <button className="nav-link" onClick={() => handleNavClick('about')}>ABOUT</button>
        <button className="nav-link" onClick={() => handleNavClick('products')}>PRODUCTS</button>
        <button className="nav-link" onClick={() => handleNavClick('contact')}>CONTACT</button>
      </nav>
    </header>
  );
};

export default Header;
