import React from 'react';
import './About.css';
import './App.css';
import aboutImage from './assets/about-image.png'; // Adjust the path as necessary

const About = () => {
  return (
    <div className="about-container" id="about" name='about'>
      <div className="about-content">
        <img src={aboutImage} alt="About Us" className="about-image" 
        />
        <div className="about-text" >
          <h2>About Us</h2>
          <p>
          As the saying goes – “If You Can Dream It, You Can Do It…”
          </p>
          <p>
          Coming from a non-business oriented family, I stand here as the Proprietor of
Flora Exports (Manufacturer &amp; Exporter of Leather Goods) one of the Leading
Manufacturer &amp; Exporter in this industry.
          </p>
          <p>
          In the early age, I had a strong aspiration deep within to do business
independently, but unfortunately had no experience or knowledge of it. Years
passed by. My service life began in the Year 1972, when I started working in the
Leather Industry and this dream of owning my own business kept coming at me
from time to time.
          </p>
          <p>
          As I got deeply invested in this work, my dedication, attachment and
determination for this trade kept on increasing day by day. With an intense
desire, a ray of hope in my mind, and support from my family and seniors, I
started with the fabrication of Leather Goods.
          </p>
          <p>
          With the flow of time, opportunities started unfolding and the possibilities kept
increasing. I literally grabbed my very first opportunity with both hands in the
year 1996. It was the cold month of February 1996, when I flew to Germany with
my first set of samples made by my own hands. I visited the factory of my first
Client and developed few samples at their place, as per their pictures, sketches
and style requirement. The owner Mr. Peter Arnold was very much impressed and
amazed to see it. The samples got approved without any question. Immediately
he placed me his first order and this is how the journey of my dreams began…
          </p>
          <p>
          As there is no age limit to seek knowledge and to discover more, till date I am still
working with my son to improve &amp; innovate the quality of my work. As each day
passes, I learn something new and the thirst for knowledge increases.
          </p>
          <p>
          I am still doing my best to polish up on my products, offer something unique to
my valued customers and provide them quality goods of international standards
so that they always remember the name of Flora Exports.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
