import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import leftArrow from './assets/home/left-arrow.png';  // Assume you have left arrow icon
import rightArrow from './assets/home/right-arrow.png';  // Assume you have right arrow icon
import photo1 from './assets/home/L1.avif';
import photo2 from './assets/home/L2.jpg';
import photo3 from './assets/home/L3.webp';
import photo4 from './assets/home/L4.jpg';
import photo5 from './assets/home/L5.jpg';
import photo6 from './assets/home/L6.jpg';
const photos = [
   photo1,
   photo2,
   photo3,
   photo4,
   photo5,
   photo6,

];

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState('right');
  const intervalRef = useRef(null);

  const startInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setDirection('right');
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    }, 6000); // Change photo every 6 seconds
  };

  useEffect(() => {
    startInterval();
    return () => clearInterval(intervalRef.current); // Clear interval on component unmount
  }, []);

  const nextPhoto = () => {
    setDirection('right');
    setCurrentIndex((currentIndex + 1) % photos.length);
    startInterval();
  };

  const prevPhoto = () => {
    setDirection('left');
    setCurrentIndex((currentIndex - 1 + photos.length) % photos.length);
    startInterval();
  };

  return (
    <div id='home' name='home' className="home-container">
      <div className="carousel-text">
        <h4>FLORA EXPORTS</h4>
        <h7>MANUFACTURER & EXPORTER OF SMALL LEATHER GOODS</h7>
      </div>
      <div className={`carousel-photo-container ${direction}`} key={currentIndex}>
        <img src={photos[currentIndex]} alt="carousel" className="carousel-photo" />
      </div>
      <button className="arrow left-arrow" onClick={prevPhoto}>
        <img src={leftArrow} alt="previous" />
      </button>
      <button className="arrow right-arrow" onClick={nextPhoto}>
        <img src={rightArrow} alt="next" />
      </button>
    </div>
  );
};

export default Home;
