import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Products.css';

const Products = () => {
  const navigate = useNavigate();

  const navigateToCategory = (category) => {
    navigate(`/products/${category}`);
  };

  return (
    <div className="products-container" id="products">
      <h2>Our Products</h2>
      <div className="product-list">
        <div className="product-item men" onClick={() => navigateToCategory('men')}>
          <div className="product-overlay">
            <h3>For Men</h3>
          </div>
        </div>
        <div className="product-item women" onClick={() => navigateToCategory('women')}>
          <div className="product-overlay">
            <h3>For Women</h3>
          </div>
        </div>
        <div className="product-item accessories" onClick={() => navigateToCategory('accessories')}>
          <div className="product-overlay">
            <h3>Accessories</h3>
          </div>
        </div>
        <div className="product-item belt" onClick={() => navigateToCategory('belt')}>
          <div className="product-overlay">
            <h3>Belts</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
