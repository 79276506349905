import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AccessoriesProducts.css'; // Import your CSS file for styling
import ap1_1 from './assets/accessories/ap1_1.jpg';
import ap1_2 from './assets/accessories/ap1_2.jpg';

import ap3_1 from './assets/accessories/ap3_1.jpg';
import ap4_1 from './assets/accessories/ap4_1.jpg';
import ap4_2 from './assets/accessories/ap4_2.jpg';
import ap5_1 from './assets/accessories/ap5_1.jpg';



const AccessoriesProducts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="category-container">
      <h2>Accessories</h2>
      <div className="card-container">
      <div className="card">
          <Slider {...settings}>
            <div>
              <img src={ap4_1} alt="Accessory 2 - 1" />
            </div>
            <div>
              <img src={ap4_2} alt="Accessory 2 - 1" />
            </div>
          </Slider>
          <h3>1998</h3>
        </div>


        <div className="card">
        <div>
              <img src={ap3_1} alt="Accessory 2 - 1" />
            </div>
          <h3>1573</h3>
        </div>
        <div className="card">
          <Slider {...settings}>
            <div>
              <img src={ap1_1} alt="Accessory 1 - 1" />
            </div>
            <div>
              <img src={ap1_2} alt="Accessory 1 - 2" />
            </div>
          </Slider>
          <h3>COGNAC 007</h3>
        </div>
        <div className="card">
            <div>
              <img src={ap5_1} alt="Accessory 2 - 1" />
            </div>
          <h3>L-13</h3>
        </div>

        {/*Add more cards as needed */}
      </div>
    </div>
  );
};

export default AccessoriesProducts;
