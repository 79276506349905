import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faPhone, faFax, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import instagram from './assets/footer/instagram.svg';
import gmail from './assets/footer/gmail.svg';
import whatsapp from './assets/footer/whatsapp.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section address">
          <h3>Address</h3>
          <p>17/2/H/13 Smith Lane, Kolkata-700 013, India</p>
        </div>
        <div className="footer-section contact">
          <h3>Contact</h3>
          <p><FontAwesomeIcon icon={faMobileAlt} />  Mobile phone : (+91) 9830921265</p>
          <p><FontAwesomeIcon icon={faPhone} />  Telephone : 00-91-033-2265 2655</p>
          <p><FontAwesomeIcon icon={faFax} />  Fax : 00-91-033-2265 1354</p>
          <p><FontAwesomeIcon icon={faEnvelope} />  Email : aftab.floraexports@gmail.com</p>
        </div>
        <div className="footer-section social">
          <h3>Follow Us</h3>
          <div className="social-buttons">
          <a href="https://www.instagram.com/floraexports/" target="_blank" rel="noopener noreferrer">
              <img src={instagram} alt="Instagram" />
            </a>
            <a href="https://wa.me/+919830921265" target="_blank" rel="noopener noreferrer">
              <img src={whatsapp} alt="Whatsapp" />
            </a>
            <a href="mailto:aftab.floraexports@gmail.com" target="_blank" rel="noopener noreferrer">
              <img src={gmail} alt="Gmail" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Flora Exports. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
